import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private accounts = new BehaviorSubject([]);
  constructor(private apiService: ApiService) { }


  get getAccounts(){
    return this.accounts.asObservable();
  }



  loadAccounts(){
    this.apiService.get(environment.accountList).then(res=>{
      
      this.accounts.next(res.result);
    });
  }

  
}
